<template>
  <v-app light>
    <v-main>
      <div class="container">
        <!-- title bar -->
        <v-card
          color="grayoil"
          class="header pa-4 my-2 d-flex flex-row justify-space-between"
          elevation="0"
        >
          <div class="d-flex flex-row">
            <div class="mr-3">
              <v-icon large color="white"> mdi-water-opacity </v-icon>
            </div>
            <div class="d-flex flex-column">
              <h2>Oil spill</h2>
              <div>
                {{ title }}
              </div>
            </div>
          </div>
          <div>
            <a href="/msp/oilspill-graph" target="_blank" class="mx-4">
              <v-btn x-large color="thrid" dark>Graph</v-btn>
            </a>
            <a :href="webgnomeUrl" target="_blank">
              <v-btn x-large color="primary" dark> เข้าใช้งานแบบจำลอง </v-btn>
            </a>
          </div>
        </v-card>

        <!-- selection panal -->
        <v-card class="d-flex flex-row pa-12 mb-4" elevation="0">
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <h3>เลือกประเภทกระแสน้ำ/ลม</h3>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    :items="itemsCurrent"
                    v-model="typeCurrent"
                    item-text="name"
                    item-value="value"
                    tex
                    label="ประเภทกระแสน้ำหรือลม"
                    dense
                    solo
                  ></v-select>
                </v-col>
              </v-row>
              <h3 class="mb-4">เลือกช่วงเวลา</h3>

              <div class="d-flex flex-column pa-0">
                <!-- date / time -->
                <v-row class="mb-2">
                  <v-col cols="12" sm="12" md="12">
                    <vc-date-picker
                      v-model="range"
                      mode="dateTime"
                      is-range
                      is24hr
                      :update-on-input="false"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <div class="d-flex justify-center items-center">
                          <v-text-field
                            :value="inputValue.start"
                            v-on="inputEvents.start"
                            filled
                            prepend-inner-icon="mdi-calendar"
                            hide-details
                            class=""
                          />
                          <v-icon> mdi-arrow-right-thin </v-icon>
                          <v-text-field
                            :value="inputValue.end"
                            v-on="inputEvents.end"
                            filled
                            prepend-inner-icon="mdi-calendar"
                            hide-details
                            class=""
                          />
                        </div>
                      </template>
                    </vc-date-picker>
                  </v-col>
                </v-row>

                <!-- North Latitude -->
                <h3 class="my-4">ขอบเขต</h3>
                <v-row class="d-flex justify-end">
                  <v-btn class="" @click="drawRectangle">
                    <div
                      style="
                        background-color: blue;
                        width: 10px;
                        height: 10px;
                        border-radius: unset;
                      "
                      class="mr-2"
                    ></div>
                    <div>Draw Polygon</div>
                  </v-btn>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="North Latitude"
                      v-model="locationBound.maxLat"
                      filled
                      prepend-inner-icon="mdi-arrow-up-thin"
                      type="number"
                      hide-details
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>

                <!-- West Longitude -->
                <v-row class="d-flex justify-center">
                  <v-col cols="12" sm="5" md="5">
                    <v-text-field
                      label=" West Longitude"
                      v-model="locationBound.minLon"
                      filled
                      prepend-inner-icon="mdi-arrow-left-thin"
                      type="number"
                      hide-details
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="5" md="5">
                    <v-text-field
                      label=" East Longitude"
                      v-model="locationBound.maxLon"
                      filled
                      prepend-inner-icon="mdi-arrow-right-thin"
                      type="number"
                      hide-details
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>

                <!-- South Latitude -->
                <v-row class="d-flex justify-center">
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="South Latitude"
                      v-model="locationBound.minLat"
                      filled
                      prepend-inner-icon="mdi-arrow-down-thin"
                      type="number"
                      hide-details
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-divider class="my-5"></v-divider>

                <div class="d-flex justify-end">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    v-show="isDownload"
                  ></v-progress-circular>
                  <v-btn
                    @click="getData()"
                    class="mb-4"
                    width="40%"
                    depressed
                    color="primary"
                    large
                    :dark="!isDownload"
                    :disabled="isDownload"
                    >{{ isDownload ? "Downloading" : "Get Data"
                    }}<v-icon right v-show="!isDownload">
                      mdi-arrow-right-thin
                    </v-icon></v-btn
                  >
                </div>
              </div>
            </v-col>

            <v-col cols="12" sm="12" md="6" style="position: relative; min-height: 500px">
              <longdo-map @load="init" :zoom="8" :location="locTrat" />

              <v-card
                style="position: absolute; padding: 8px; bottom: 60px; right: 20px"
                class="d-flex align-center"
                elevation="0"
              >
                <div
                  style="
                    background-color: red;
                    width: 2px;
                    height: 10px;
                    border-radius: unset;
                  "
                  class="mr-2"
                ></div>
                <div style="font-size: 12px">พื้นที่รับสัญญาณเรดาร์ชายฝั่ง สทอภ.</div>
              </v-card>
            </v-col>
          </v-row>
        </v-card>

        <v-card class="d-flex flex-row pa-12" elevation="0">
          <v-row>
            <h3>นิยาม</h3>
            <br /><br />
            <ol>
              <li>
                <b>กระแสน้ำกริดจากแบบจำลอง AI</b> คือ ข้อมูลกระแสน้ำที่ได้จากแบบจำลองพัฒนา
                โดยจากเทคนิค Machine Learning โดยใช้ข้อมูลจากสถานีเรดาร์ชายฝั่งของ GISTDA
                จำนวน 3 ปี สามารถพยากรณ์ทิศทางของกระแสน้ำได้ตามช่วงเวลาที่กำหนด มีขนาดกริด
                2x2 กม. ทุก ๆ 1 ชม. ฟอร์แมต NetCDF (*.nc) หน่วยวัด m/s
              </li>
              <li>
                <b>กระแสน้ำกริดจากสถานีเรดาร์ชายฝั่ง</b> คือ
                ข้อมูลกระแสน้ำที่ตรวจวัดจากสถานีเรดาร์ชายฝั่งของ GISTDA
                สามารถดึงข้อมูลย้อนหลังได้เป็นเวลา 3 เดือนจนถึงปัจจุบัน มีขนาดกริด 2x2 กม.
                ทุก ๆ 1 ชม. ฟอร์แมต NetCDF (*.nc) หน่วยวัด m/s
              </li>
              <li>
                <b>กระแสน้ำกริดจากการพยากรณ์</b> คือ ข้อมูลกระแสน้ำที่ได้จากการพยากรณ์
                สามารถดึงข้อมูลย้อนหลังตั้งแต่ปี 2020 จนถึงปัจจุบัน มีขนาดกริด 10x10 กม.
                ทุก ๆ 3 ชม. ฟอร์แมต NetCDF (*.nc) หน่วยวัด m/s
              </li>
              <li>
                <b>กระแสลมอนุกรมเวลาจากสถานีเรดาร์ชายฝั่ง</b> คือ
                ข้อมูลลมที่ตรวจวัดได้จากสถานีเรดาร์ชายฝั่งของ GISTDA
                สามารถดึงข้อมูลย้อนหลังตั้งแต่ปี 2018 จนถึงปัจจุบัน
                โดยระบบเลือกข้อมูลจากสถานีที่อยู่ใกล้ศูนย์กลางกรอบพื้นที่
                ตามขอบเขตที่กำหนด เป็นข้อมูล Time series มีข้อมูลทุก ๆ 5 นาที ฟอร์แมต
                (*.wnd). หน่วยวัด knots
              </li>
              <li>
                <b>กระแสลมอนุกรมเวลาพยากรณ์</b> คือ ข้อมูลลมที่ได้จากการพยากรณ์
                สามารถดึงข้อมูลจนถึงปัจจุบัน
                โดยเลือกข้อมูลจากริดที่อยู่ใกล้ศูนย์กลางพื้นที่ ตามขอบเขตที่กำหนดหนด
                เป็นข้อมูล Time series ทุก ๆ 3 ชม. ฟอร์แมต (*.wnd). หน่วยวัด knots
              </li>
              <li>
                <b>กระแสลมกริดจากการพยากรณ์</b> คือ ข้อมูลลมที่ได้จากการพยากรณ์
                สามารถดึงข้อมูลจนถึงปัจจุบัน ในรูปแบบกริดขนาด 55x55 กม. ทุก ๆ 3 ชม.
                ฟอร์แมต NetCDF (*.nc). หน่วยวัด knots
              </li>
              <li>
                  <b>กระแสน้ำกริดจากแบบจำลอง AI (ข้อมูลกระแสน้ำย้อนหลัง 30 ปี)</b> คือ ข้อมูลกระแสน้ำที่ได้จากแบบจำลองพัฒนา โดยจากเทคนิค Machine Learning โดยใช้ข้อมูลจากแบบจำลอง Delft3d จำนวน 30 ปี สามารถพยากรณ์ทิศทางของกระแสน้ำได้ตามช่วงเวลาที่กำหนด มีขนาดกริด 1x1 กม. ทุก ๆ 1 ชม. ฟอร์แมต NetCDF (*.nc), GeoJSON (*.geojson), Comma-Separated Values (*.csv) หน่วยวัด m/s
              </li>
            </ol>
          </v-row>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Oilspill",
  data: () => ({
    title: "การสร้างโมเดลการเคลื่อนที่ของกระแสน้ำ",
    locTrat: { lat: 11.804101, lon: 102.749328 },
    range: {
      start: undefined,
      end: undefined,
    },
    masks: {
      input: "YYYY-MM-DD",
    },
    locationBound: {
      minLat: 0,
      minLon: 0,
      maxLat: 0,
      maxLon: 0,
    },
    gridSpace: 1,
    timeStep: null,
    map: undefined,
    cellValue: ["-", "-", "-", "-"],
    gistdaLayerName: ["v_recent_gul6", "recent_gulf", "HF_Stations"],
    webgnomeUrl: process.env.VUE_APP_WEBGNOME_URL,
    isDownload: false,
    oilPolygonArea: null,
    typeCurrent: "CurrentPredict",
    itemsCurrent: [
      { name: "กระแสน้ำกริดจากแบบจำลอง AI", value: "CurrentPredict" }, // nc
      { name: "กระแสน้ำกริดจากสถานีเรดาร์ชายฝั่ง", value: "HFRadarDB" }, // nc
      { name: "กระแสน้ำกริดจากการพยากรณ์", value: "RtofsModel" }, // nc
      { name: "กระแสลมอนุกรมเวลาจากสถานี", value: "WindDB" }, // wnd
      { name: "กระแสลมอนุกรมเวลาพยากรณ์", value: "WindModel" }, // wnd
      { name: "กระแสลมกริดจากการพยากรณ์ ", value: "WindGridModel" }, // nc
      {
        name: "การพยากรณ์ระยะสั้น (short term prediction)",
        value: "ShortTermPrediction",
      }, // nc
    ],
  }),
  components: {},
  created() {},
  mounted() {
    this.initDate();
  },
  computed: {
    // a computed getter
  },
  methods: {
    initDate() {
      let self = this;
      let date = new Date();
      var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
      var localISOTime = new Date(Date.now() - tzoffset).toISOString().slice(0, -1);

      self.range = {
        start: date.setDate(date.getDate() - 1),
        end: localISOTime,
      };
    },
    init(map) {
      let self = this;
      self.map = map;
      self.map.Ui.Toolbar.visible(false);
      self.map.Ui.LayerSelector.visible(false);
      self.map.Ui.Fullscreen.visible(false);

      self.map.Event.bind("overlayChange", function (overlay) {
        if (
          overlay instanceof window.longdo.Polygon &&
          !(overlay instanceof window.longdo.Rectangle)
        ) {
          self.setPolygonBound(overlay);
        }
      });

      // self.map.Layers.add(
      //   new window.longdo.Layer("", {
      //     type: window.longdo.LayerType.TMS,
      //     url: process.env.VUE_APP_THAILAND_SATELLITE,
      //     zoomRange: { min: 1, max: 20 },
      //     source: { min: 1, max: 16 },
      //     zoomOffset: 0,
      //   })
      // );

      self.addWMS("geonode:convex_hull");

      self.gistdaLayerName.forEach((name) => {
        self.map.Layers.add(
          new window.longdo.Layer(name, {
            type: window.longdo.LayerType.WMS,
            url: "https://ocean.gistda.or.th/geoserver/coastalradar/wms",
            extraQuery: "buffer=16",
            // url: process.env.VUE_APP_GEOSERVER,
          })
        );
      });
    },
    addWMS(layerName) {
      this.map.Layers.add(
        new window.longdo.Layer(layerName, {
          type: window.longdo.LayerType.WMS,
          url: process.env.VUE_APP_GEOSERVER,
        })
      );
    },
    mapBound(val) {
      this.map.bound(val);
      this.map.resize();
    },
    getData() {
      if (this.typeCurrent === "ShortTermPrediction") {
        this.getShortTerm();
      } else {
        this.getncFile();
      }
    },
    async getShortTerm() {
      let self = this;
      // Step 1: Login to get the access token => 20250204 no need to login anymore
      // const loginUrl = `${process.env.VUE_APP_OILSPILL_API_SHORT_TERM}login`;
      // const loginPayload = {
      //   username: process.env.VUE_APP_OILSPILL_USERNAME,
      //   password: process.env.VUE_APP_OILSPILL_PASSWORD,
      // };

      try {
        // Login request
        // const loginResponse = await fetch(loginUrl, {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify(loginPayload),
        // });

        // if (!loginResponse.ok) {
        //   throw new Error(`Login failed: ${loginResponse.statusText}`);
        // }

        // const loginData = await loginResponse.json();
        // const accessToken = loginData.access_token;

        // Step 2: Use the access token to make the GET call
        const getUrl = `${
          process.env.VUE_APP_OILSPILL_API_SHORT_TERM
        }CurrentPredict30ML/${self.formatDate(self.range.start)}/${self.formatDate(
          self.range.end
        )}/${self.locationBound.minLon},${self.locationBound.minLat},${
          self.locationBound.maxLon
        },${self.locationBound.maxLat}/nc`;

        const getResponse = await fetch(getUrl, {
          method: "GET",
          headers: {
            // Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
        });

        if (!getResponse.ok) {
          throw new Error(`GET request failed: ${getResponse.statusText}`);
        }

        // Extract blob from the response
        const blob = await getResponse.blob();

        // Extract filename from Content-Disposition header (if available)
        const contentDisposition = getResponse.headers.get("Content-Disposition");

        let filename = `${self.typeCurrent}_${self.formatDate(
          new Date()
        )}${self.checkTypeFile(self.typeCurrent)}`;

        if (contentDisposition) {
          const match = contentDisposition.match(/filename="(.+)"/);
          if (match && match[1]) {
            filename = match[1];
          }
        }

        // Create a temporary link and trigger the download
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();

        // Clean up
        URL.revokeObjectURL(link.href);
      } catch (error) {
        console.error("Error:", error.message);
      }
    },
    getncFile() {
      const self = this;
      let url;

      // Build the URL based on the type of prediction

      url = `${process.env.VUE_APP_OILSPILL_API}${self.typeCurrent}/${self.formatDate(
        self.range.start
      )}/${self.formatDate(self.range.end)}/${self.locationBound.minLon},${
        self.locationBound.minLat
      },${self.locationBound.maxLon},${self.locationBound.maxLat}`;

      self.isDownload = true;

      // Prepare fetch options
      const fetchOptions = {
        method: "GET",
      };

      fetch(url, fetchOptions)
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          // Extract blob from the response
          const blob = await response.blob();

          // Extract filename from Content-Disposition header (if available)
          const contentDisposition = response.headers.get("Content-Disposition");

          let filename = `${self.typeCurrent}_${self.formatDate(
            new Date()
          )}${self.checkTypeFile(self.typeCurrent)}`;

          if (contentDisposition) {
            const match = contentDisposition.match(/filename="(.+)"/);
            if (match && match[1]) {
              filename = match[1];
            }
          }

          // Create a temporary link and trigger the download
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();

          // Clean up
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          console.error("Download failed:", error);
          alert("ไม่สามารถดาวน์โหลดข้อมูลได้");
        })
        .finally(() => {
          self.isDownload = false;
        });
    },

    drawRectangle() {
      let self = this;
      let location = self.map.location();
      self.map.Overlays.remove(self.oilPolygonArea);
      self.oilPolygonArea = new window.longdo.Rectangle(
        {
          lon: location.lon,
          lat: location.lat,
        },
        {
          width: 0.2,
          height: 0.2,
        },
        {
          editable: true,
          lineColor: "rgba(0, 0, 255, 0.9)",
          fillColor: "rgba(0, 0, 255, 0.3)",
        }
      );

      self.map.Overlays.add(self.oilPolygonArea);
      self.setPolygonBound(self.oilPolygonArea);
    },
    checkTypeFile(val) {
      const fileTypeMap = {
        WindDB: ".wnd",
        WindModel: ".wnd",
        // Add more specific mappings if needed
      };
      return fileTypeMap[val] || ".nc"; // Default to ".nc" if the value is not found in the map
    },
    setPolygonBound(obj) {
      let self = this;
      let tempPolygonBound = obj.location();
      self.locationBound = {
        minLat: tempPolygonBound[2].lat.toFixed(6),
        minLon: tempPolygonBound[0].lon.toFixed(6),
        maxLat: tempPolygonBound[0].lat.toFixed(6),
        maxLon: tempPolygonBound[1].lon.toFixed(6),
      };
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    },
    formatDate(date) {
      return (
        [
          date.getFullYear(),
          this.padTo2Digits(date.getMonth() + 1),
          this.padTo2Digits(date.getDate()),
        ].join("-") +
        " " +
        [
          this.padTo2Digits(date.getHours()),
          this.padTo2Digits(date.getMinutes()),
          this.padTo2Digits(date.getSeconds()),
        ].join(":")
      );
    },
  },
  watch: {},
};
</script>

<style scoped>
.header {
  color: white;
}

#map {
  width: 100%;
  height: 100%;
}

#map-result {
  width: 100%;
  height: 100%;
}

.cell-title {
  font-size: 12px;
}

.cell-value {
  color: red;
}
</style>
